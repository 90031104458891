<head>
<meta name="keywords" content="scurteaza link,shop,cum sa faci bani online,masini de vanzare,casa de vanzare,apartement de vanzare,shop online,fa bani online,produse ieftine,cumpara acum,scaun de gaming,gaming,download free,descarca,descarca gratuit,cumpara,primeste oferta,oferte,telefon,iphone,">

</head>
<material-navbar></material-navbar>

<div class="container">
    <ad-host slot="ads.splash.top"></ad-host>

    <div class="link-panel">
       
    
        <div class="info">
            <h1 trans>You are about to be redirected to another page.</h1>
            <div class="actions">
                <button mat-raised-button color="accent" *ngIf="(timeout$ | async) > 0; else timeoutDone">
                    <span trans>Redirect In</span> {{ timeout$ | async }}
                </button>
                <ng-template #timeoutDone>
                    
                </ng-template>
&nbsp;



<button mat-raised-button color="accent">

<a [href]="link.long_url" trans>Go to link</a>

</button>



                &nbsp;

                            </div>

<ad-host slot="ads.splash.bottom"></ad-host>


            <p class="info-text" trans>You are about to be redirected to another page. We are not responsible for the content of that page or the consequences it may have on you.</p>
        </div>
    </div>

    

    <app-footer></app-footer>
</div>
